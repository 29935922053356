var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white"
  }, [_vm._v(_vm._s(_vm.$t("client")) + " Amanah Warisan Berhad")])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "\n          container\n          card\n          bg-light\n          job-box\n          rounded\n          shadow\n          border-0\n          overflow-hidden\n        "
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.form.fullname,
      expression: "form.fullname"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Nama Penuh",
      "name": ""
    },
    domProps: {
      "value": _vm.form.fullname
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "fullname", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('b-button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.searchQuery
    }
  }, [_vm._v(_vm._s(_vm.$t("search")))])], 1)])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_vm.testators == null ? _c('div', {
    staticClass: "text-center"
  }, [_c('h5', {
    staticClass: "p-5"
  }, [_vm._v("Loading...")])]) : _vm._e(), _vm.testators ? _c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', [_c('center', [_vm._v(_vm._s(_vm.$t("index")))])], 1), _c('th', [_vm._v(" " + _vm._s(_vm.$t("fullname")))]), _c('th', [_vm._v(" " + _vm._s(_vm.$t("ic")))]), _c('th', [_c('center', [_vm._v(_vm._s(_vm.$t("amendment.no-wasiat")))])], 1), _c('th', [_vm._v(" " + _vm._s(_vm.$t("role")))]), _c('th', {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm.$t("action")))])])]), _c('tbody', [_vm.testators.length == 0 ? _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "6"
    }
  }, [_c('h5', {
    staticClass: "p-5"
  }, [_vm._v(_vm._s(_vm.$t("no-records")))])])]) : _vm._e(), _vm._l(_vm.testators, function (testator, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_c('center', [_vm._v(_vm._s(index))])], 1), _c('td', [_vm._v(_vm._s(testator.name))]), _c('td', [_vm._v(_vm._s(testator.ic_number))]), _c('td', [_c('center', [_vm._v(" " + _vm._s(testator.wasiat ? testator.wasiat.wasiat_no : "-") + " ")])], 1), _c('td', [_vm._v(_vm._s(_vm.$t("client")))]), _c('td', {
      staticClass: "text-right"
    }, [_c('router-link', {
      staticClass: "btn btn-primary btn-sm",
      attrs: {
        "to": {
          name: 'client.info',
          params: {
            client_id: testator.id
          }
        }
      }
    }, [_c('img', {
      attrs: {
        "src": "/images/icon/view.svg",
        "height": "22px",
        "alt": ""
      }
    })]), _vm.currentUser.username == 'staff' ? _c('button', {
      staticClass: "btn ml-1 btn-primary btn-sm",
      attrs: {
        "type": "button"
      },
      on: {
        "click": function ($event) {
          return _vm.resendEmail(testator.id);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": "/images/icon/email2.svg",
        "height": "22px",
        "alt": ""
      }
    })]) : _vm._e()], 1)]);
  })], 2)])]) : _vm._e()])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-8"
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.pagination.totalRows,
      "per-page": _vm.pagination.perPage,
      "aria-controls": "my-table"
    },
    on: {
      "change": _vm.pageClicks
    },
    model: {
      value: _vm.pagination.currentPage,
      callback: function ($$v) {
        _vm.$set(_vm.pagination, "currentPage", $$v);
      },
      expression: "pagination.currentPage"
    }
  })], 1), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form custom-form"
  }, [_c('div', {
    staticClass: "form-group mb-0"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.pagination.perPage,
      expression: "pagination.perPage"
    }],
    staticClass: "form-control custom-select",
    attrs: {
      "id": "Sortbylist-job"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.pagination, "perPage", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        return _vm.changePerPage();
      }]
    }
  }, [_c('option', {
    attrs: {
      "value": "10"
    }
  }, [_vm._v("10 " + _vm._s(_vm.$t("per-page")))]), _c('option', {
    attrs: {
      "value": "25"
    }
  }, [_vm._v("25 " + _vm._s(_vm.$t("per-page")))]), _c('option', {
    attrs: {
      "value": "50"
    }
  }, [_vm._v("50 " + _vm._s(_vm.$t("per-page")))])])])])])])])])])])])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }